
import Flickity from "flickity-fade";


document.addEventListener("turbolinks:load", () => {
  const cfg = {
    cellAlign: "left",
    contain: true,
    pageDots: false,
    freeScroll: true,
    initialIndex: 0,
    wrapAround: true,
    draggable: false,
  };

  const sliders = document.querySelectorAll('.cms_comment_slider_container');

  sliders.forEach((sliderr, index) => {
    const flickityInstance = new Flickity(sliderr, cfg);

    const previewBtn = sliderr.parentElement.querySelector('.flickity-prev-next-button.previous');
    const nextBtn = sliderr.parentElement.querySelector('.flickity-prev-next-button.next');

    if (nextBtn) {
      nextBtn.classList.add('cms_comment_slider_button');
      nextBtn.innerHTML = `
        <img src="/images/sliderRight.svg" alt="Next Arrow" class="cms_slider_icon" style="margin: 0 auto;" />
      `;
    }

    if (previewBtn) {
      previewBtn.classList.add('cms_comment_slider_button');
      previewBtn.innerHTML = `
        <img src="/images/sliderLeft.svg" alt="Previous Arrow" class="cms_slider_icon cms_slider_icon_prev" style="margin: 0 18px 0 auto;" />
      `;
      previewBtn.removeAttribute('role');
    }

    const slider = sliderr.closest(".new_comment_slider");
    if (!slider) {
      return;
    }

    function updateSliderHeight() {
      const activeComment = slider.querySelector(".carousel-cell.is-selected .cms_comment");
      const flickityViewport = slider.querySelector(".flickity-viewport");

      if (activeComment && flickityViewport) {
        const images = activeComment.querySelectorAll("img");
        if (images.length > 0) {
          Promise.all(
            Array.from(images).map((img) => {
              if (img.complete) return Promise.resolve();
              return new Promise((resolve) => img.addEventListener("load", resolve));
            })
          ).then(() => {
            const newHeight = activeComment.offsetHeight + 20;
            flickityViewport.style.height = `${newHeight}px`;
          });
        } else {
          const newHeight = activeComment.offsetHeight + 20;
          flickityViewport.style.height = `${newHeight}px`;
        }
      }
    }

    if (flickityInstance) {
      flickityInstance.on("settle", () => {
        updateSliderHeight();
      });
    }

    updateSliderHeight();

    if (nextBtn) {
      nextBtn.addEventListener("click", () => {
        updateSliderHeight();
      });
    }

    if (previewBtn) {
      previewBtn.addEventListener("click", () => {
        updateSliderHeight();
      });
    }

    slider.addEventListener("click", (event) => {
      if (event.target.closest(".read_more_button") || event.target.closest(".read_less_button")) {
        updateSliderHeight();
      }
    });
  });
});
document.addEventListener("turbolinks:load", () => {
  const commentDivs = document.querySelectorAll('.comment_content');

  commentDivs.forEach((commentDiv) => {
    const fullText = commentDiv.getAttribute('data-full-text');
    const readMoreThreshold = parseInt(commentDiv.getAttribute('data-read-more-threshold')) || 0;
    const readMoreOnlyOnMobile = parseInt(commentDiv.getAttribute('data-read-more-only-on-mobile')) || 0;

    const isMobile = window.innerWidth <= 480;
    let truncatedComment = fullText;

    if (readMoreOnlyOnMobile === 1) {
      if (isMobile && readMoreThreshold > 0 && fullText.split(' ').length > readMoreThreshold) {
        truncatedComment = fullText.split(' ').slice(0, readMoreThreshold).join(' ') + '...';
      }
    } else if (readMoreThreshold > 0 && fullText.split(' ').length > readMoreThreshold) {
      truncatedComment = fullText.split(' ').slice(0, readMoreThreshold).join(' ') + '...';
    }
    commentDiv.textContent = truncatedComment;
    const readMoreButton = commentDiv.parentElement.querySelector('.read_more_button');
    const readLessButton = commentDiv.parentElement.querySelector('.read_less_button');

    if (truncatedComment !== fullText) {
      readMoreButton.style.display = 'inline';
      readLessButton.style.display = 'none';
    }

    if (readMoreButton) {
      readMoreButton.addEventListener('click', () => {
        commentDiv.textContent = fullText;
        readMoreButton.style.display = 'none';
        readLessButton.style.display = 'inline';
      });
    }

    if (readLessButton) {
      readLessButton.addEventListener('click', () => {
        commentDiv.textContent = truncatedComment;
        readLessButton.style.display = 'none';
        readMoreButton.style.display = 'inline';
      });
    }
  });
});

document.addEventListener("turbolinks:load", () => {
  const commentDivs = document.querySelectorAll('.slider_comment');
  commentDivs.forEach((commentDiv) => {
    const fullText = commentDiv.getAttribute('data-full-text');
    const readMoreThreshold = parseInt(commentDiv.getAttribute('data-read-more-threshold')) || 0;
    const readMoreOnlyOnMobile = parseInt(commentDiv.getAttribute('data-read-more-only-on-mobile')) || 0;
    const isMobile = window.innerWidth <= 480;
    function truncateHtml(html, threshold) {
      const words = html.split(' ');
      if (words.length <= threshold) return html;
      return words.slice(0, threshold).join(' ') + '...';
    }
    let truncatedComment = fullText;
    if (readMoreOnlyOnMobile === 1) {
      if (isMobile && readMoreThreshold > 0) {
        truncatedComment = truncateHtml(fullText, readMoreThreshold);
      }
    } else if (readMoreThreshold > 0) {
      truncatedComment = truncateHtml(fullText, readMoreThreshold);
    }
    commentDiv.innerHTML = truncatedComment;
    const readMoreButton = commentDiv.parentElement.parentElement.querySelector('.read_more_button');
    const readLessButton = commentDiv.parentElement.parentElement.querySelector('.read_less_button');
    if (truncatedComment !== fullText) {
      readMoreButton.style.display = 'block';
      readLessButton.style.display = 'none';
    }
    if (readMoreButton) {
      readMoreButton.addEventListener('click', () => {
        commentDiv.innerHTML = fullText;
        readMoreButton.style.display = 'none';
        readLessButton.style.display = 'block';
      });
    }
    if (readLessButton) {
      readLessButton.addEventListener('click', () => {
        commentDiv.innerHTML = truncatedComment;
        readLessButton.style.display = 'none';
        readMoreButton.style.display = 'block';
      });
    }
  });
});
document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".copyLinkImage").forEach((icon) => {
    icon.addEventListener("click", () => {
      const section = icon.closest(".new_section");
      if (section) {
        const sectionId = section.id;
        const link = `${window.location}#${sectionId}`;
        navigator.clipboard.writeText(link).then(()=>{
          alert('link copied')
        })
      }
    });
  });
});

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".accordion-header").forEach((header) => {
    header.addEventListener("click", () => {
      const content = header.nextElementSibling;
      const icon = header.querySelector(".accordion-icon");
      const isOpen = content.style.maxHeight && content.style.maxHeight !== "0px";
      if (isOpen) {
        content.style.maxHeight = null;
        content.style.opacity = "0";
        icon.classList.remove("rotate-180");
        header.classList.add('new-accordion-open');
        header.classList.remove('new-accordion-close');
        content.classList.remove('accordion-content-open');
        header.parentElement.classList.remove('element-open');
        content.classList.remove('new_accordion_item_container_open_item')
      } else {
        content.classList.add('new_accordion_item_container_open_item')
        content.style.maxHeight = content.scrollHeight + 100 + "px";
        content.style.opacity = "1";
        icon.classList.add("rotate-180");
        header.classList.remove('new-accordion-open');
        header.classList.add('new-accordion-close');
        content.classList.add('accordion-content-open');
        header.parentElement.classList.add('element-open');
      }
    });
  });
});

document.addEventListener("turbolinks:load", () => {
  const commentDivs = document.querySelectorAll('.new_book_simple_description');
  commentDivs.forEach((commentDiv) => {
    const fullText = commentDiv.getAttribute('data-full-text');
    const readMoreThreshold = parseInt(commentDiv.getAttribute('data-read-more-threshold')) || 0;
    const readMoreOnlyOnMobile = parseInt(commentDiv.getAttribute('data-read-more-only-on-mobile')) || 0;
    const isMobile = window.innerWidth <= 480;
    function truncateHtml(html, threshold) {
      const words = html.split(' ');
      if (words.length <= threshold) return html;
      return words.slice(0, threshold).join(' ') + '...';
    }
    let truncatedComment = fullText;
    if (readMoreOnlyOnMobile === 1) {
      if (isMobile && readMoreThreshold > 0) {
        truncatedComment = truncateHtml(fullText, readMoreThreshold);
      }
    } else if (readMoreThreshold > 0) {
      truncatedComment = truncateHtml(fullText, readMoreThreshold);
    }
    commentDiv.innerHTML = truncatedComment;
    const readMoreButton = commentDiv.parentElement.parentElement.querySelector('.new_book_simple_read_more_button');
    const readLessButton = commentDiv.parentElement.parentElement.querySelector('.new_book_simple_read_less_button');
    if (truncatedComment !== fullText) {
      readMoreButton.style.display = 'block';
      readLessButton.style.display = 'none';
    }
    if (readMoreButton) {
      readMoreButton.addEventListener('click', () => {
        commentDiv.innerHTML = fullText;
        readMoreButton.style.display = 'none';
        readLessButton.style.display = 'block';
      });
    }
    if (readLessButton) {
      readLessButton.addEventListener('click', () => {
        commentDiv.innerHTML = truncatedComment;
        readLessButton.style.display = 'none';
        readMoreButton.style.display = 'block';
      });
    }
  });
});

document.addEventListener("turbolinks:load", () => {
  const commentDivs = document.querySelectorAll('.comment_content_multiline');

  commentDivs.forEach((commentDiv) => {
    const fullText = commentDiv.getAttribute('data-full-text');
    const readMoreThreshold = parseInt(commentDiv.getAttribute('data-read-more-threshold')) || 0;
    const readMoreOnlyOnMobile = parseInt(commentDiv.getAttribute('data-read-more-only-on-mobile')) || 0;
    const isMobile = window.innerWidth <= 480;
    function truncateHtml(html, threshold) {
      const words = html.split(' ');
      if (words.length <= threshold) return html;
      return words.slice(0, threshold).join(' ') + '...';
    }
    let truncatedComment = fullText;
    if (readMoreOnlyOnMobile === 1) {
      if (isMobile && readMoreThreshold > 0) {
        truncatedComment = truncateHtml(fullText, readMoreThreshold);
      }
    } else if (readMoreThreshold > 0) {
      truncatedComment = truncateHtml(fullText, readMoreThreshold);
    }
    commentDiv.innerHTML = truncatedComment;
    const readMoreButton = commentDiv.parentElement.parentElement.querySelector('.read_more_button');
    const readLessButton = commentDiv.parentElement.parentElement.querySelector('.read_less_button');
    if (truncatedComment !== fullText) {
      readMoreButton.style.display = 'block';
      readLessButton.style.display = 'none';
    }
    if (readMoreButton) {
      readMoreButton.addEventListener('click', () => {
        commentDiv.innerHTML = fullText;
        readMoreButton.style.display = 'none';
        readLessButton.style.display = 'block';
      });
    }
    if (readLessButton) {
      readLessButton.addEventListener('click', () => {
        commentDiv.innerHTML = truncatedComment;
        readLessButton.style.display = 'none';
        readMoreButton.style.display = 'block';
      });
    }
  });
});

document.addEventListener("turbolinks:load", () => {
  const albumShowMoreButtons = document.querySelectorAll(".cms_album-show-more");
  albumShowMoreButtons.forEach((albumShowMoreButton) => {
    const album = albumShowMoreButton.closest(".cms_album")

    albumShowMoreButton.addEventListener("click", () => {
      album.querySelectorAll(".hidden_crm_album_item").forEach((item) => {
        item.classList.remove("hidden")
        albumShowMoreButton.remove()
      })
    });
  });
});
