document.addEventListener('turbolinks:load', function () {
  const sliderSection = document.querySelector('#people-like-you');

  if (sliderSection) {
    const prevButton = sliderSection.querySelector('.flickity-prev-next-button.previous');
    const nextButton = sliderSection.querySelector('.flickity-prev-next-button.next');

    if (prevButton) {
      prevButton.innerHTML = '<img src="/images/sliderLeft.svg" alt="Previous Arrow" style="margin: 0 13px 0 auto;" />';
    }

    if (nextButton) {
      nextButton.innerHTML = '<img src="/images/sliderRight.svg" alt="Next Arrow" style="margin: 0 auto" />';
    }
  }

  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    document.documentElement.classList.add('is-safari');
  }
});

document.addEventListener('turbolinks:load', function () {
  const sliderSection = document.querySelector('#news-slideshow');

  if (sliderSection) {
    const prevButton = sliderSection.querySelector('.flickity-prev-next-button.previous');
    const nextButton = sliderSection.querySelector('.flickity-prev-next-button.next');

    if (prevButton) {
      prevButton.innerHTML = '<img src="/images/sliderLeft.svg" alt="Previous Arrow" style="margin: 0 13px 0 auto;" />';
    }

    if (nextButton) {
      nextButton.innerHTML = '<img src="/images/sliderRight.svg" alt="Next Arrow" style="margin: 0 auto" />';
    }
  }
});

document.addEventListener('turbolinks:load', function () {
  const sliderSection = document.querySelector('.mainCarousel');

  if (sliderSection) {
    const prevButton = sliderSection.querySelector('.flickity-prev-next-button.previous');
    const nextButton = sliderSection.querySelector('.flickity-prev-next-button.next');

    if (prevButton) {
      prevButton.innerHTML = '<img src="/images/sliderLeft.svg" alt="Previous Arrow" style="margin: 0 13px 0 auto;" />';
    }

    if (nextButton) {
      nextButton.innerHTML = '<img src="/images/sliderRight.svg" alt="Next Arrow" style="margin: 0 auto" />';
    }
  }
});
